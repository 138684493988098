<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <b-form
      class="p-2"
      @submit.enter.prevent="handleSubmit(addTag)"
    >
      <validation-provider
        #default="validationContext"
        name="Tag Name"
        rules="required|min:3"
      >
        <b-form-group
          label="Tag"
          label-for="tag"
        >
          <b-form-input
            id="tag"
            v-model="tag"
            :state="getValidationState(validationContext)"
            autofocus
            placeholder="Tag Name"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
          variant="primary"
          type="submit"
        >
          Add
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'AddTag',
  data() {
    return {
      tag: '',
    }
  },
  setup() {
    // Form Validation
    const {
      getValidationState,
    } = formValidation()

    const { successfulOperationAlert, showErrors } = handleAlerts()

    return {
      getValidationState,
      successfulOperationAlert,
      showErrors,
    }
  },
  methods: {
    addTag() {
      this.$entities.post('internalops/tags', {
        tag_name: this.tag,
      }).then(() => {
        this.$bvModal.hide('add-modal')
        this.successfulOperationAlert('Tag is added successfully')
        this.$emit('refresh')
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
  },
}
</script>

<style>

</style>
