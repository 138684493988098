<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <b-form
      class="p-2"
      @submit.enter.prevent="handleSubmit(updateTag)"
    >
      <validation-provider
        #default="validationContext"
        name="Name"
        rules="required|min:3"
      >
        <b-form-group
          label="Edit"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="tagData.tag_name"
            autofocus
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
          variant="primary"
          type="submit"
        >
          Save
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'EditTags',
  props: {
    tagData: { type: Object, default: () => {} },
  },
  setup() {
    const { successfulOperationAlert, showErrors } = handleAlerts()
    const {
      getValidationState,
    } = formValidation()
    return {
      successfulOperationAlert,
      showErrors,
      getValidationState,

    }
  },
  methods: {
    updateTag() {
      this.$entities.post(`internalops/tags/${this.tagData.id}?_method=PUT`, {
        tag_name: this.tagData.tag_name,
      }).then(() => {
        this.$bvModal.hide('edit-modal')
        this.successfulOperationAlert('Tag is updated successfully')
        this.$emit('refresh')
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
  },
}
</script>
