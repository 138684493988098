<template>
  <b-table
    ref="table"
    :items="getTags"
    :fields="tableColumns"
    :current-page="pagination.currentPage"
    :per-page="pagination.perPage"
    responsive="lg"
  >
    <template #cell(id)="{item}">
      <span> # {{ item.id }} </span>
    </template>
    <template #cell(name)="{item}">
      <span> {{ item.tag_name }}</span>
    </template>
    <!-- Column: Actions -->
    <template #cell(actions)="{item}">
      <b-dropdown
        variant="link"
        no-caret
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>

        <toggle-status
          :item="item"
          :activate-url="activateUrl"
          :deactivate-url="deactivateUrl"
        />

        <b-dropdown-item
          v-b-modal.edit-modal
          variant="outline-primary"
          @click="getTagById(item.id)"
        >
          <feather-icon icon="EditIcon" />
          <span> Edit </span>
        </b-dropdown-item>
        <b-dropdown-item @click="deleteRecord(deleteEndPoint,item.id)">
          <feather-icon icon="TrashIcon" />
          <span
            class="align-middle ml-50"
          >Delete</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>

<script>
import deleteTag from '@/common/compositions/common/deleteRecord'
import ToggleStatus from '@/common/components/GeneralSettings/common/ToggleStatus.vue'

export default {
  name: 'TagsTable',
  components: {
    ToggleStatus,
  },
  props: {
    getTags: { type: Function, default: () => [] },
    pagination: { type: Object, default: () => {} },
  },
  data() {
    return {
      tableColumns: [
        { key: 'id', label: '#', sortable: true },
        { key: 'name', sortable: true, searchable: true },
        { key: 'status', formatter: v => (v ? 'Activated' : 'Deactivated') },
        { key: 'actions' },
      ],
      deleteEndPoint: `${process.env.VUE_APP_ENTITIES_BASE_URL}/internalops/tags/`,
      activateUrl: '/internalops/tag-activate/',
      deactivateUrl: '/internalops/tag-deactivate/',
    }
  },
  setup() {
    const { deleteRecord, table } = deleteTag()

    return {
      deleteRecord, table,
    }
  },
  methods: {
    getTagById(id) {
      this.$entities.get(`internalops/tags/${id}`).then(res => {
        this.$emit('update:tag', res.data.data)
      })
    },
  },
}
</script>
