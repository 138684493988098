<template>
  <b-row align-h="center">
    <b-col>
      <div class="text-right">
        <b-button
          v-b-modal.add-modal
          variant="primary"
          class="mb-1"
        >
          <span class="text-nowrap">Add Tag</span>
        </b-button>
      </div>
      <b-card header="Search">
        <b-row>
          <b-col>
            <search
              placeholder="Name"
              :search-query.sync="searchQuery"
              @refresh="refreshTable"
            />
          </b-col>
          <b-col
            md="2"
            class="mt-1 mt-md-0"
          >
            <search-button @refresh="refreshTable" />
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <tags-table
          ref="parentTable"
          v-bind="{
            getTags,
            pagination
          }"
          :tag.sync="tagData"
        />
      </b-card>

      <pagination
        :total-rows="pagination.totalRows"
        :per-page="pagination.perPage"
        :current-page.sync="pagination.currentPage"
      />
    </b-col>
    <div class="d-flex align-items-center justify-content-end m-2">
      <!-- modal Add Tag -->
      <b-modal
        id="add-modal"
        centered
        title="Add Tag"
        hide-footer
      >
        <add-tags
          @refresh="refreshTable"
        />
      </b-modal>
      <!-- Edit -->
      <b-modal
        id="edit-modal"
        centered
        title="Edit Tag"
        hide-footer
      >
        <edit-tags
          v-bind="{tagData}"
          @refresh="refreshTable"
        />
      </b-modal>
    </div>
  </b-row>
</template>

<script>
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import Search from '@/common/components/common/Table/Search.vue'
import AddTags from '@/common/components/GeneralSettings/tags/AddTags.vue'
import EditTags from '@/common/components/GeneralSettings/tags/EditTags.vue'
import TagsTable from '@/common/components/GeneralSettings/tags/TagsTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'

import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  components: {
    AddTags,
    EditTags,
    TagsTable,
    Pagination,
    Search,
    SearchButton,
  },
  data() {
    return {
      pagination: {
        totalRows: 5,
        currentPage: 1,
        perPage: 15,
      },
      tagData: {},
      searchQuery: '',
    }
  },
  setup() {
    const { successfulOperationAlert, showErrors } = handleAlerts()

    return {
      successfulOperationAlert,
      showErrors,
    }
  },
  methods: {
    getTags() {
      const promise = this.$entities.get(`internalops/commercial_entity/getTags?page=${this.pagination.currentPage}&paginate=true`, {
        params: {
          name: this.searchQuery,
        },
      })
      return promise.then(res => {
        const items = res.data.data
        this.pagination.perPage = res.data.per_page
        this.pagination.totalRows = res.data.total
        return items || []
      }).catch(() => [])
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>
